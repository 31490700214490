import * as Sentry from "@sentry/browser";
import { Integrations as TracingIntegrations } from "@sentry/tracing";

import { isObject } from "./index";

const DSN = process.env.REACT_APP_SENTRY_DSN;
const STAGE = process.env.REACT_APP_STAGE || "dev";
const TRACES_SAMPLE_RATE = process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE;

let enabled = false;

export const init = () => {
  const tracingOrigins = [new RegExp(`api-${STAGE}.storeroomlogix.com`)];
  const tracesSampleRate = Number(TRACES_SAMPLE_RATE);
  Sentry.init({
    dsn: DSN,
    integrations: [
      new TracingIntegrations.BrowserTracing({
        traceXHR: false,
        tracingOrigins
      })
    ],
    tracesSampleRate
  });
  enabled = true;
};

export const captureException = (err, context, options) => {
  if (!enabled) return;
  Sentry.withScope(scope => {
    if (context) {
      scope.setTag("context", context);
      if (context === "api-call" && window.__api_call) {
        scope.setExtra("api-call", window.__api_call);
        delete window.__api_call;
        if (window.__session_id) {
          scope.setExtra("session-id", window.__session_id);
          delete window.__session_id;
        }
        if (window.__api_version) {
          scope.setExtra("api-version", window.__api_version);
          delete window.__api_version;
        }
      } else if (context === "response-time") {
        const { extras } = options;
        scope.setLevel("warning");
        scope.setExtras(extras);
      } else if (context === "sign-in-issue") {
        scope.setLevel("warning");
      }
    }
    Sentry.captureException(err);
  });
};

export const setUser = ({ username }) =>
  enabled &&
  Sentry.configureScope(scope => {
    scope.setUser({ username });
  });

export const setTag = (tag, value) =>
  enabled &&
  Sentry.configureScope(scope => {
    scope.setTag(tag, value);
  });

const RegexMessages = [
  /^(The user|The RFID Id|User group|ShipTo with number) .+ present/,
  /^Location with current attributes already exists/,
  /^The location with product part sku \[.+?\] already exists/,
  /^ReplenishmentItem was not found by key/,
  /^Distributor \[.+?\] does not have product with part sku/,
  /^Difference between min: \[.+?\] and max: \[.+?\] values/,
  /^Operation failed! Canˈt delete ShipTo with assigned serial number/,
  /^Incorrect username or password/,
  /^User does not exist/,
  /^Import finished/
];

export const isNotFiltering = error => {
  const message = ((isObject(error) ? error.message : error) || "").trim();
  if (!message) return false;

  const found = RegexMessages.findIndex(regex => regex.test(message));
  return found === -1;
};

export const setPagePath = route => {
  const { pathname = "", hash = "" } = route || {};
  const notEmptyString = str => Boolean(str) && !Number(str);

  const path = String(pathname).split("/").filter(notEmptyString);

  hash && path.push(String(hash).replace(/^#/, ""));

  setTag("page", path.join(" > "));
};
